import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
// import './lib/foundation-explicit-pieces';


$(document).foundation();

	var scroll_pos = 0;
   	$(document).scroll(function() { 
      	scroll_pos = $(this).scrollTop();
         if(scroll_pos > 100) {
         	$('#uitwerking-menu').css('background-color', '#564148');
			} else {
				$('#uitwerking-menu').css('background-color', 'rgba(255,245,250,0)');
			}
        })
        
	   $('.menu>li').click(function(){
      $('.menu-icon').click();   
    })

	var mq = window.matchMedia("(min-width: 1024px)");
	if (mq.matches) {
			setTimeout(function(){ 
			$('.home').addClass('animate-fade-in');
			$('.home-animation-wrapper').addClass('is-animating');
			},1500);
			setTimeout(function(){
			$('.ondersteuning').addClass('animate-fade-out');
			},6500);

	var ondersteuningTop = $("#ondersteuning_maatschappelijke_deelname").offset().top -500;
	var ondersteuningBottom = ondersteuningTop + $("#ondersteuning_maatschappelijke_deelname").height();
	 	
		$(window).scroll(function() {
			 var curentScroll = $(window).scrollTop();
			 	if (curentScroll > ondersteuningTop && curentScroll < ondersteuningBottom) {
			 		$('.ondersteuning').addClass('animate-fade-in').removeClass('animate-fade-out');
			 		$('.ondersteuning-animation-wrapper').addClass('is-animating');
				}
			 	else {
			 		$('.ondersteuning').removeClass('animate-fade-in').addClass('animate-fade-out');
			 		$('.ondersteuning-animation-wrapper').removeClass('is-animating');
			 	}
		});
	var teamTop = $("#team_dagbesteding_individuele_ondersteuning").offset().top -500;
	var teamBottom = teamTop + $("#team_dagbesteding_individuele_ondersteuning").height() + 200;
		
		$(window).scroll(function() {
			 var curentScroll = $(window).scrollTop();
			 	if (curentScroll > teamTop && curentScroll < teamBottom) {
			 		$('.team').addClass('animate-fade-in').removeClass('animate-fade-out');
			 		$('.team-animation-wrapper').addClass('is-animating');
				}
			 	else {
			 		$('.team').removeClass('animate-fade-in').addClass('animate-fade-out');
			 		$('.team-animation-wrapper').removeClass('is-animating');
			 	}
		});
	var locatiesTop = $("#locaties_dagbesteding").offset().top -200;
	var locatiesBottom = locatiesTop + $("#locaties_dagbesteding").height();
		
		$(window).scroll(function() {
			 var curentScroll = $(window).scrollTop();
			 	if (curentScroll > locatiesTop && curentScroll < locatiesBottom) {
			 		$('.locaties').addClass('animate-fade-in').removeClass('animate-fade-out');
			 		$('.locaties-animation-wrapper').addClass('is-animating');
				}
			 	else {
			 		$('.locaties').removeClass('animate-fade-in').addClass('animate-fade-out');
			 		$('.locaties-animation-wrapper').removeClass('is-animating');
			 	}
		});
	var contactTop = $("#contact_maatschappelijke_deelname").offset().top -200;
	var contactBottom = contactTop + $("#contact_maatschappelijke_deelname").height();
		
		$(window).scroll(function() {
			 var curentScroll = $(window).scrollTop();
			 	if (curentScroll > contactTop && curentScroll < contactBottom) {
			 		$('.contact').addClass('animate-fade-in').removeClass('animate-fade-out');
			 		$('.contact-animation-wrapper').addClass('is-animating');
				}
			 	else {
			 		$('.contact').removeClass('animate-fade-in').addClass('animate-fade-out');
			 		$('.contact-animation-wrapper').removeClass('is-animating');
			 	}
		});
}

